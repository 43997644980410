import { useEffect, useState, useContext } from 'react'
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
import { Link } from "react-router-dom";
import BankContext from '../../Context/bank'
import LangContext from '../../Context/lang';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1400 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 1400, min: 991 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 991, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const ArticlesSimilaires = ({ id , vente}) => {

  const { Lang, ProductLang, Global } = useContext(LangContext);


  const [Product, setProduct] = useState([]);

  const { bankActive, setBankActive, GetTaksitPrice } = useContext(BankContext);



  useEffect(() => {

    const fetchData = async () => {
      // setLoading(true);
      try {
        const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vente/ventes_similary/${id}/${vente}`);
        // console.log(response[0]);
        setProduct(response['data']);
        console.log(response['data'])

      } catch (error) {
        // console.error(error.message);
      }
      // setLoading(false);
    }

    fetchData();

    // axios.get('https://opr.diardzair.com.dz/ws/produit?hash=5d7e96e84f868e460df9f988e07d8460').then(response => {
    //       // console.log(response.data[0])
    //       setProduct(response.data[0])
    //       console.log(Product)
    //     });

  }, [id]);


  return (

    <section className="product-similary mt-1 pt-5 pb-5">
      {Product &&

        <div className="container">
          <div className="title-section">{(ProductLang['Simailaire'][Lang])}</div>
          <div className="row gy-5">

            {/* <Carousel autoPlay={true} draggable={false} autoPlaySpeed={1000} responsive={responsive}> */}

            <Swiper
              // install Swiper modules
              modules={[Navigation, Autoplay]}
              // loop={true}
              // spaceBetween={20}
              // slidesPerView={6}
              breakpoints={{
                // when window width is >= 640px
                640: {
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 8
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 20,

                },

              }}
              navigation
            // autoplay={{
            //   delay: 1000,
            //   disableOnInteraction: false,
            // }}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            >

              {Product && Product.map((item, i) => (

                <SwiperSlide key={`ProductSimilary${item?.hash}${i}`}>


                  <div className="col">
                    <Link reloadDocument to={`/product/${item?.boutique_slug}/${item?.vente_slug}`} href="#">

                      <div className="card-product">
                        <div className="first-section-product">
                          <div className="img-product mb-3"><img className="" alt={item?.titre_produit} src={`${process.env.REACT_APP_LOCAL_URL}/images/ventes/${item?.image1}`} /></div>
                          <div className="title-product">{item?.titre_produit?.length > 30 ? item?.titre_produit?.substring(0, 30) + '....' : item?.titre_produit?.substring(0, 30)}</div>
                          <div className="mark-product">{item?.marque}</div>
                        </div>
                        <div className="product-page-price price-product">
                          {item?.active == true ?
                            <div className="tag-taksit">تقسيط</div>
                            :
                            <div className='tag-dartaksit-category-cadeaux mt-0 mb-0'><img width={'50px'} src="https://opr.diardzair.com.dz/uploads/img/logo/cadeaux.png" /> </div>}
                          {/* <div className="tag-taksit">تقسيط</div> */}
                          <div className={Lang === 'ar' ? "price d-flex" : "price"}> {(Lang === 'ar' ? <> <span className='me-1'> {(item?.free == false ? Global['Da'][Lang] : Global['Dajust'][Lang])} </span> {item?.free == false ? GetTaksitPrice(parseInt(item?.prix))['default']?.toLocaleString() : parseInt(item?.prix)?.toLocaleString()}</> : <>{item?.free == false ? GetTaksitPrice(parseInt(item?.prix))['default']?.toLocaleString() : parseInt(item?.prix)?.toLocaleString()}<span> {(item?.free == false ? Global['Da'][Lang] : Global['Dajust'][Lang])}</span></>)} </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                </SwiperSlide>

              ))
              }
            </Swiper>

            {/* </Carousel> */}
          </div>
        </div>
      }
    </section>

  )
}

export default ArticlesSimilaires