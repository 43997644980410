import React from 'react'

function LoadData() {
    return (
        <div className='dashboard-loader'>
            <div className='cart-sum-loader flex-column'>
                <p className='mb-1'>Chargement</p>
                <div><div className="spinner-grow text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                    <div className="spinner-grow text-danger" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow text-danger" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadData