import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';
import LoadData from '../../components/Global/LoadData';



const CreateVent = () => {

    const [Category, setCategory] = useState([])
    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/categoriedz`);
                // console.log(response[0]);
                setCategory(response['data']);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);


    const [BoutiquesList, setBoutiquesList] = useState([])
    const [Boutique, setBoutique] = useState([])
    const [LoadDataWait, setLoadDataWait] = useState(false)

    useEffect(() => {

        const fetchData = async () => {
            setLoadDataWait(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/boutique/alluserboutiques/userid`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                // console.log(response[0]);
                setBoutiquesList(response['data']);
                // console.log(DarTaksitCat)

            } catch (error) {
                setLoadDataWait(false);
                console.error(error.message);
            }
            setLoadDataWait(false);
        }

        fetchData();

    }, []);

    console.log(Category);

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const MySwal = withReactContent(Swal)





    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);
    const hiddenFileInput3 = useRef(null);
    const hiddenFileInput4 = useRef(null);
    const hiddenFileInput5 = useRef(null);





    // useEffect(() => {


    //     if (Auth) {
    //         console.log('hi!');

    //     } else {
    //         console.log('no!');
    //         return navigate("/");
    //     }

    //     console.log(Auth);


    // }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [Titreduproduit, setTitreduproduit] = useState('')
    const [Réferenceduproduit, setRéferenceduproduit] = useState('')
    const [Marque, setMarque] = useState('')
    const [Catégorie, setCatégorie] = useState('')
    const [Quantité, setQuantité] = useState('')
    const [Quantitéunit, setQuantitéunit] = useState('')
    const [Datedébutdevente, setDatedébutdevente] = useState('')
    const [Datefindevente, setDatefindevente] = useState('')
    const [Prix, setPrix] = useState('')
    const [Conditionderetour, setConditionderetour] = useState('')
    const [Délaisdannulation, setDélaisdannulation] = useState('')
    const [Promo, setPromo] = useState(false)
    const [Stock, setStock] = useState(true)
    const [Valid, setValid] = useState(true)
    const [Prixpromotionnel, setPrixpromotionnel] = useState('')
    const [Datedébutpromotion, setDatedébutpromotion] = useState('')
    const [Datefinpromotion, setDatefinpromotion] = useState('')
    const [Caractéristiques, setCaractéristiques] = useState('')
    const [Description, setDescription] = useState('')
    const [Shortdescription, setShortdescription] = useState('')
    const [Image1, setImage1] = useState('')
    const [Image2, setImage2] = useState('')
    const [Image3, setImage3] = useState('')
    const [Image4, setImage4] = useState('')
    const [Image5, setImage5] = useState('')



    const [preview1, setPreview1] = useState()
    const [preview2, setPreview2] = useState()
    const [preview3, setPreview3] = useState()
    const [preview4, setPreview4] = useState()
    const [preview5, setPreview5] = useState()

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    console.log(Promo);

    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ Titreduproduit: '', Réferenceduproduit: '', Marque: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {


        if (parseInt(Boutique) <= 0 || isNaN(Boutique) || Boutique == '') {
            seterrors((prev) => ({ ...prev, Boutique: 'Vous devez sélectionner La Boutique !!' }))
        } else {
            seterrors((prev) => ({ ...prev, Boutique: '' }))
        }
        if (Titreduproduit?.toString().trim().length > 100 || Titreduproduit?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Titreduproduit: 'Veuillez saisir un titre produit correct !!' }))
        } else {
            seterrors((prev) => ({ ...prev, Titreduproduit: '' }))
        }

        if (Réferenceduproduit?.toString().trim().length > 20 || Réferenceduproduit?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Réferenceduproduit: 'Veuillez saisir la référence du produit !!' }))
        } else {
            seterrors((prev) => ({ ...prev, Réferenceduproduit: '' }))
        }

        if (Marque?.toString().trim().length > 20 || Marque?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Marque: 'Veuillez saisir la marque du produit' }))
        } else {
            seterrors((prev) => ({ ...prev, Marque: '' }))
        }

        if (Catégorie?.toString().trim().length > 20 || Catégorie?.toString().trim() == '' || !onlyNumbers(Catégorie)) {
            seterrors((prev) => ({ ...prev, Catégorie: 'Veuillez séléctionner une catégorie de produit!!' }))
        } else {
            seterrors((prev) => ({ ...prev, Catégorie: '' }))
        }

        if (parseInt(Quantité) > 1000000000 || parseInt(Quantité) < 0 || !onlyNumbers(Quantité)) {
            seterrors((prev) => ({ ...prev, Quantité: 'Veuillez saisir la quantité du produit(la quantité doit étre un nombre entier) ' }))
        } else {
            seterrors((prev) => ({ ...prev, Quantité: '' }))
        }

        if (parseInt(Quantitéunit) > 1000000000 || parseInt(Quantitéunit) < 0 || !onlyNumbers(Quantitéunit) || parseInt(Quantitéunit) > parseInt(Quantité)) {
            seterrors((prev) => ({ ...prev, Quantitéunit: 'Veuillez saisir la quantité unitaire du produit(la quantité unitaire doit étre un nombre entier positif) Elle doit également être inférieure ou égale à la quantité totale' }))
        } else {
            seterrors((prev) => ({ ...prev, Quantitéunit: '' }))
        }


        // if (Quantité.trim().length > 5 || Quantité.trim() == '' || !onlyNumbers(Quantité)) {
        //     seterrors((prev) => ({ ...prev, Quantité: 'Veuillez saisir la quantité du produit(la quantité doit étre un nombre entier) ' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Quantité: '' }))
        // }

        // if (Quantitéunit.trim().length > 5 || Quantitéunit.trim() == '' || !onlyNumbers(Quantitéunit)) {
        //     seterrors((prev) => ({ ...prev, Quantitéunit: 'Veuillez saisir la quantité unitaire du produit(la quantité unitaire doit étre un nombre entier positif) ' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Quantitéunit: '' }))
        // }

        // if (Datedébutdevente.trim() == '' || dateIsValid(new Date(Datedébutdevente)) == false || afterToday(new Date(), new Date(Datedébutdevente)) == false) {
        //     seterrors((prev) => ({ ...prev, Datedébutdevente: 'Veuillez saisir une date correct !!' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Datedébutdevente: '' }))
        // }

        // if (Datefindevente.trim() == '' || dateIsValid(new Date(Datefindevente)) == false || afterToday(new Date(Datedébutdevente), new Date(Datefindevente)) == false) {
        //     seterrors((prev) => ({ ...prev, Datefindevente: 'Veuillez séléctionner une date correct ( la date de fin de vente doit étre aprés la date début de vente)!!' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Datefindevente: '' }))
        // }


        // if (Prix.trim().length > 10 || Prix.trim() == '' || !onlyNumbers(Prix)) {
        //     seterrors((prev) => ({ ...prev, Prix: 'Veuillez saisir le prix du produit (le prix du produit doit étre un nombre entier positif)!!' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Prix: '' }))
        // }

        if (parseInt(Prix) > 1000000000 || parseInt(Prix) <= 0 || !onlyNumbers(Prix)) {
            seterrors((prev) => ({ ...prev, Prix: 'Veuillez saisir le prix du produit (le prix du produit doit étre un nombre entier positif)!!' }))
        } else {
            seterrors((prev) => ({ ...prev, Prix: '' }))
        }

        if (Conditionderetour?.toString().trim() !== 'oui' && Conditionderetour?.toString().trim() !== 'non') {
            seterrors((prev) => ({ ...prev, Conditionderetour: 'Veuillez saisir les condition de retour du produit' }))
        } else {
            seterrors((prev) => ({ ...prev, Conditionderetour: '' }))
        }

        if (Caractéristiques?.toString().trim().length > 600) {
            seterrors((prev) => ({ ...prev, Caractéristiques: 'Veuillez saisir les caractéristique du produit' }))
        } else {
            seterrors((prev) => ({ ...prev, Caractéristiques: '' }))
        }

        if (Description?.toString().trim().length > 6000) {
            seterrors((prev) => ({ ...prev, Description: 'Veuillez saisir la description du produit' }))
        } else {
            seterrors((prev) => ({ ...prev, Description: '' }))
        }

        if (Shortdescription?.toString().trim().length > 6000) {
            seterrors((prev) => ({ ...prev, Shortdescription: 'Veuillez saisir une petite description du produit' }))
        } else {
            seterrors((prev) => ({ ...prev, Shortdescription: '' }))
        }

        if (Promo == true) {

            if (parseInt(Prixpromotionnel) > 10000000000 || parseInt(Prixpromotionnel) == '' || !onlyNumbers(Prixpromotionnel) || parseInt(Prixpromotionnel) >= parseInt(Prix)) {
                seterrors((prev) => ({ ...prev, Prixpromotionnel: 'Veuillez saisir un prix promotionnel correct (le prix promotionnel doit étre un nombre entier inférieur au prix réel du produit)!!' }))
            } else {
                seterrors((prev) => ({ ...prev, Prixpromotionnel: '' }))
            }

            // if (!Datedébutpromotion || dateIsValid(new Date(Datedébutpromotion)) == false || afterToday(new Date(), new Date(Datedébutpromotion)) == false || afterToday(new Date(Datedébutdevente), new Date(Datedébutpromotion) == false)) {
            //     seterrors((prev) => ({ ...prev, Datedébutpromotion: 'Veuillez séléctionner une date correct(la date de début de promotion doit étre entre la date debut mise en vente et la date fin de mise en vente)!!' }))
            // } else {
            //     seterrors((prev) => ({ ...prev, Datedébutpromotion: '' }))

            // }

            // if (Datefinpromotion || dateIsValid(new Date(Datefinpromotion)) == false || afterToday(new Date(Datedébutpromotion), new Date(Datefinpromotion)) == false || afterToday(new Date(Datefinpromotion), new Date(Datefindevente)) == false) {
            //     seterrors((prev) => ({ ...prev, Datefinpromotion: 'Veuillez séléctionner une date correct ( la date de fin de promotion ne doit pas dépasser la date fin de vente)!!' }))
            // } else {
            //     seterrors((prev) => ({ ...prev, Datefinpromotion: '' }))
            // }

        } else {
            seterrors((prev) => ({ ...prev, Prixpromotionnel: '' }))
            seterrors((prev) => ({ ...prev, Datedébutpromotion: '' }))
            seterrors((prev) => ({ ...prev, Datefinpromotion: '' }))
        }


        if (Image1) {
            if (Image1.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image1: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image1: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image1: '' }))
        }

        if (Image2) {
            if (Image2.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image2: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image2: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image2: '' }))
        }
        if (Image3) {
            if (Image3.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image3: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image3: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image3: '' }))
        }

        if (Image4) {
            if (Image4.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image4: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image4: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image4: '' }))
        }

        if (Image5) {
            if (Image5.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image5: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image5: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image5: '' }))
        }

        if (!Image1 && !Image2 && !Image3 && !Image4 && !Image5) {
            seterrors((prev) => ({ ...prev, Imageall: "Merci de mettre au moins une photo du produit" }))
        } else {
            seterrors((prev) => ({ ...prev, Imageall: '' }))
        }



    }

    useEffect(() => {

        Validate();

    }, [Boutique, Titreduproduit, Réferenceduproduit, Marque, Catégorie, Quantité, Quantitéunit, Datedébutdevente, Datefindevente, Prix, Conditionderetour, Caractéristiques, Description, Shortdescription, Promo, Datedébutpromotion, Datefinpromotion, Prixpromotionnel, Image1, Image2, Image3, Image4, Image5])


    useEffect(() => {
        if (!Image1) {
            setPreview1(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image1)
        setPreview1(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image1])


    useEffect(() => {
        if (!Image2) {
            setPreview2(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image2)
        setPreview2(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image2])


    useEffect(() => {
        if (!Image3) {
            setPreview3(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image3)
        setPreview3(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image3])

    useEffect(() => {
        if (!Image4) {
            setPreview4(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image4)
        setPreview4(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image4])

    useEffect(() => {
        if (!Image5) {
            setPreview5(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image5)
        setPreview5(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image5])


    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])





    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)

        if (errors.Boutique !== '' || errors.Titreduproduit !== '' || errors.Réferenceduproduit !== '' || errors.Marque !== '' || errors.Catégorie !== '' || errors.Quantité !== '' || errors.Quantitéunit !== ''
            || errors.Prix !== '' || errors.Conditionderetour !== '' || errors.Caractéristiques !== '' || errors.Description !== '' || errors.Shortdescription !== ''
            || errors.Prixpromotionnel !== '' || errors.Imageall !== '' || errors.Image1 !== '' || errors.Image2 !== '' || errors.Image3 !== '' || errors.Image4 !== '' || errors.Image5 !== ''
        ) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }



        const formData = new FormData();
        formData.append("titre_produit", Titreduproduit);
        formData.append("ref_produit", Réferenceduproduit);
        formData.append("marque", Marque);
        formData.append("id_categorie", Catégorie);
        formData.append("qte", Quantité);
        formData.append("qte_unit", Quantitéunit);
        formData.append("debut_miseVente", Datedébutdevente);
        formData.append("fin_miseVente", Datefindevente);
        formData.append("prix", Prix);
        formData.append("condition_retour", Conditionderetour);

        // formData.append("selectedFile", Délaisdannulation);
        formData.append("promo", Promo == true ? 1 : 0);
        formData.append("active", Stock == true ? 1 : 0);
        formData.append("valid", Valid == true ? 1 : 0);
        formData.append("prix_promo", Prixpromotionnel);
        formData.append("debut_promo", Datedébutpromotion);
        formData.append("fin_promo", Datefinpromotion);
        formData.append("caracteristiques", Caractéristiques);
        formData.append("description", Description);
        formData.append("ShortDescription", Shortdescription);
        formData.append("images", Image1);
        formData.append("images", Image2);
        formData.append("images", Image3);
        formData.append("images", Image4);
        formData.append("images", Image5);







        // try {
        //   const response = await axios.post({
        //     method: "post",
        //     url: "${process.env.REACT_APP_LOCAL_URL}/vente/new_vente/11112222",
        //     data: formData,
        //     headers: { 
        //         "Content-Type": "multipart/form-data" ,
        //          Authorization: `Bearer ${Auth?.token}` 
        //     },
        //   });

        //   console.log(response);
        // } catch(error) {
        //   console.log(error)
        // }

        setLoading(true)


        axios.post(`${process.env.REACT_APP_LOCAL_URL}/vente/new_vente/${Boutique}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)
                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Les données ont été ajoutées avec succès.',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {
                        window.location = "/listventesadmin";
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }








    return (
        <div className='dashboard'>

            {Loading &&
                <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>




                 <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Créer Un Vente <span>Store</span></div>
                    <div className='page-content'>
                        {/* {err.length > 0 && err.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}

                        {
                            LoadDataWait ? <LoadData /> : ''
                        }

                        {
                            !LoadDataWait && BoutiquesList?.length == 0  ? <div className='boutique-alert-vide'>Vous devez ouvrir une boutique avant d'ajouter des produits</div> : ""
                        }
                        {!LoadDataWait ?<form className="row g-5">

                            <div className='container py-3'>
                                {err?.length > 0 && err?.map((item, i) => (

                                    item.constructor == String ?
                                        <div className="alert-store" role="alert">
                                            - {item}
                                        </div>
                                        : ''

                                ))
                                }

                            </div>

                            <div class="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label for="inputState" class="form-label">Boutiques</label>
                                <select id="inputState" class="form-select" onChange={(e) => setBoutique(e.target.value)}>
                                    <option selected disabled value="">Boutiques</option>
                                    {BoutiquesList?.length > 0 && BoutiquesList?.map((item, i) => (
                                        <option value={item?.id_boutique}>{item?.nom}</option>
                                    ))
                                    }
                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.Boutique !== '' ? errors.Boutique : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Titre du produit</label>
                                <input maxLength={100} type="text" onChange={(e) => setTitreduproduit(e.target.value)} value={Titreduproduit} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Titreduproduit !== '' ? errors.Titreduproduit : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Réference du produit</label>
                                <input type="text" maxLength={20} onChange={(e) => setRéferenceduproduit(e.target.value)} value={Réferenceduproduit} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Réferenceduproduit !== '' ? errors.Réferenceduproduit : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Marque</label>
                                <input type="text" maxLength={20} onChange={(e) => setMarque(e.target.value)} value={Marque} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Marque !== '' ? errors.Marque : ''}</div>
                            </div>
                            <div class="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label for="inputState" class="form-label">Catégorie</label>
                                <select id="inputState" class="form-select" onChange={(e) => setCatégorie(e.target.value)}>
                                    <option selected disabled value="">Catégories</option>
                                    {Category?.length > 0 && Category?.map((item, i) => (
                                        <option value={item.id_cat}>{item.cat_name}</option>
                                    ))
                                    }
                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.Catégorie !== '' ? errors.Catégorie : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Quantité</label>
                                <input type="tel" className="form-control" onChange={(e) => setQuantité(e.target.value.replace(/[^0-9]+/g, ''))} value={Quantité} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Quantité !== '' ? errors.Quantité : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Quantité unit</label>
                                <input type="tel" className="form-control" onChange={(e) => setQuantitéunit(e.target.value.replace(/[^0-9]+/g, ''))} value={Quantitéunit} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Quantitéunit !== '' ? errors.Quantitéunit : ''}</div>
                            </div>
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Date début de vente</label>
                                <input type="date" className="form-control" onChange={(e) => setDatedébutdevente(e.target.value)} value={Datedébutdevente} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Datedébutdevente !== '' ? errors.Datedébutdevente : ''}</div>

                            </div> */}
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Date fin de vente</label>
                                <input type="date" className="form-control" onChange={(e) => setDatefindevente(e.target.value)} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Datefindevente !== '' ? errors.Datefindevente : ''}</div>

                            </div> */}
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Prix</label>
                                <input type="tel" className="form-control" onChange={(e) => setPrix(e.target.value.replace(/[^0-9]+/g, ''))} value={Prix} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Prix !== '' ? errors.Prix : ''}</div>

                            </div>
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Condition de retour</label>
                                <input type="text" className="form-control" onChange={(e) => setConditionderetour(e.target.value)} value={Conditionderetour} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Conditionderetour !== '' ? errors.Conditionderetour : ''}</div>
                            </div> */}
                            <div className="col-md-6 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Condition de retour</label>
                                <div className=''>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio100" checked={Conditionderetour == 'oui'} onChange={(e) => setConditionderetour(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                        <label className="form-check-label" htmlFor="inlineRadio100">Oui</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio200" checked={Conditionderetour == 'non'} onChange={(e) => setConditionderetour(e.target.value)} defaultValue="non" />
                                        <label className="form-check-label" htmlFor="inlineRadio200">Non</label>
                                    </div>
                                </div>
                                <div className='err-cart-sum'>{Showerrors && errors.Conditionderetour !== '' ? errors.Conditionderetour : ''}</div>
                            </div>
                            {/* <div class="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label for="inputState" class="form-label">Par</label>
                                <select id="inputState" class="form-select">
                                    <option selected>Choose...</option>
                                    <option>...</option>
                                </select>
                            </div> */}
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Délais d'annulation</label>
                                <input type="text" className="form-control" onChange={(e) => setDélaisdannulation(e.target.value)} value={Délaisdannulation} id="inputEmail4" />
                            </div> */}





                            <div className="col-md-12 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Promo</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setPromo(e.target.checked)} checked={Promo} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div>


                            {
                                Promo &&

                                <>

                                    <div className="col-md-6  m-auto mt-4 text-center">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        {/* <label htmlFor="inputEmail4" className="form-label">Prix promotionnel</label> */}
                                        <input type="tel" placeholder='Prix promotionnel' className="form-control" onChange={(e) => setPrixpromotionnel(e.target.value.replace(/[^0-9]+/g, ''))} value={Prixpromotionnel} id="inputEmail4" />
                                        <div className='err-cart-sum'>{Showerrors && Promo && errors.Prixpromotionnel !== '' ? errors.Prixpromotionnel : ''}</div>

                                    </div>
                                    {/* <div className="col-md-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputEmail4" className="form-label">Date début promotion</label>
                                        <input type="date" className="form-control" onChange={(e) => setDatedébutpromotion(e.target.value)} value={Datedébutpromotion} id="inputEmail4" />
                                        <div className='err-cart-sum'>{Showerrors && Promo && errors.Datedébutpromotion !== '' ? errors.Datedébutpromotion : ''}</div>
                                    </div> */}
                                    {/* <div className="col-md-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputEmail4" className="form-label">Date fin promotion</label>
                                        <input type="date" className="form-control" onChange={(e) => setDatefinpromotion(e.target.value)} id="inputEmail4" />
                                        <div className='err-cart-sum'>{Showerrors && Promo && errors.Datefinpromotion !== '' ? errors.Datefinpromotion : ''}</div>
                                    </div> */}
                                </>
                            }
                            <div className="col-md-12">
                                <div className="form-floating">
                                    <textarea maxLength={600} className="form-control" onChange={(e) => setCaractéristiques(e.target.value)} value={Caractéristiques} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }} />
                                    <label htmlFor="floatingTextarea2">Caractéristiques</label>
                                    <div className='err-cart-sum'>{Showerrors && errors.Caractéristiques !== '' ? errors.Caractéristiques : ''}</div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-floating">
                                    <textarea maxLength={6000} className="form-control" onChange={(e) => setDescription(e.target.value)} value={Description} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }} />
                                    <label htmlFor="floatingTextarea2">Description</label>
                                    <div className='err-cart-sum'>{Showerrors && errors.Description !== '' ? errors.Description : ''}</div>

                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-floating">
                                    <textarea maxLength={6000} className="form-control" onChange={(e) => setShortdescription(e.target.value)} value={Shortdescription} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }} />
                                    <label htmlFor="floatingTextarea2">Short Description</label>
                                    <div className='err-cart-sum'>{Showerrors && errors.Shortdescription !== '' ? errors.Shortdescription : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">En Stock</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setStock(e.target.checked)} checked={Stock} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Validé</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setValid(e.target.checked)} checked={Valid} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-between flex-wrap">
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image1 && <div className='up-close' onClick={() => setImage1(null)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput1.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image1 &&
                                            <div onClick={() => { hiddenFileInput1.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview1} />

                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image1 !== '' ? errors.Image1 : ''}</div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Imageall !== '' ? errors.Imageall : ''}</div>
                                    <input ref={hiddenFileInput1} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage1(e.target.files[0]) : setImage1()} id="formFile" />
                                </div>
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image2 && <div className='up-close' onClick={() => setImage2(null)}>X</div>}

                                        <svg onClick={() => { hiddenFileInput2.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image2 &&
                                            <div onClick={() => { hiddenFileInput2.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview2} />
                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image2 !== '' ? errors.Image2 : ''}</div>

                                    <input ref={hiddenFileInput2} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage2(e.target.files[0]) : setImage2()} id="formFile" />
                                </div>
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image3 && <div className='up-close' onClick={() => setImage3(null)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput3.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image3 &&
                                            <div onClick={() => { hiddenFileInput3.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview3} />
                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image3 !== '' ? errors.Image3 : ''}</div>

                                    <input ref={hiddenFileInput3} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage3(e.target.files[0]) : setImage3()} id="formFile" />
                                </div>
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image4 && <div className='up-close' onClick={() => setImage4(null)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput4.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image4 &&
                                            <div onClick={() => { hiddenFileInput4.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview4} />
                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image4 !== '' ? errors.Image4 : ''}</div>

                                    <input ref={hiddenFileInput4} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage4(e.target.files[0]) : setImage4()} id="formFile" />
                                </div>
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image5 && <div className='up-close' onClick={() => setImage5(null)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput5.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image5 &&
                                            <div onClick={() => { hiddenFileInput5.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview5} />
                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image5 !== '' ? errors.Image5 : ''}</div>

                                    <input ref={hiddenFileInput5} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage5(e.target.files[0]) : setImage5()} id="formFile" />
                                </div>

                            </div>

                            <div className="col-12 mt-5">
                                <button onClick={handleSubmit} className="btn btn-secondary">Ajouter</button>
                            </div>
                        </form>: ''}
                    </div>
                </div> 

            </div>

        </div>
    )
}

export default CreateVent